import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";
import Eagelpic from "../../assets/static-images/Asset.png";
import { HashLink } from 'react-router-hash-link'


const SocialShare = [
	{ Social: <FaFacebookF />, link: "https://www.facebook.com/mahmoud.ghorbani" },
	{ Social: <FaLinkedinIn />, link: " https://www.linkedin.com/in/mahmoud-ghorbani-b566b933/" },
	{ Social: <FaInstagram />, link: " https://www.instagram.com/mahmoudnghorbani/" },
	{ Social: <FaTwitter />, link: "https://twitter.com/GhorbaniMg" },
];
class HeaderThree extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
		this.stickyHeader = this.stickyHeader.bind(this);

		//  this.subMetuTrigger = this.subMetuTrigger.bind(this);
		window.addEventListener("load", function () {
			// console.log("All assets are loaded");
		});
	}
	menuTrigger() {
		document.querySelector(".header-wrapper").classList.toggle("menu-open");
		document.querySelector(".header-btn").classList.toggle("menu-open");

		// document.addEventListener("mousedown", function () {
		// 	document.querySelector(".header-wrapper").classList.remove("menu-open");

		// })
	}

	CLoseMenuTrigger() {
		document.querySelector(".header-wrapper").classList.remove("menu-open");
	}

	stickyHeader() { }


	render() {
		window.addEventListener("scroll", function () {
			var value = window.scrollY;
			if (value > 70) {
				document.querySelector(".header--fixed").classList.add("sticky");
			} else {
				document.querySelector(".header--fixed").classList.remove("sticky");
			}
		});

		window.addEventListener('click', function (event) {

			var open = document.querySelector("li")
			var elements = document.querySelectorAll(".mainmenunav")
			//var element = document.querySelectorAll(a)
			if (event.target == open) {
				// console.log(open, "open")
				document.querySelector(".header-wrapper").classList.toggle("menu-open");
				document.querySelector(".header-btn").classList.toggle("menu-open");

			} else if (event.target == elements) {
				// console.log(elements, "main")
				document.querySelector(".header-wrapper").classList.toggle("menu-open");
				document.querySelector(".header-btn").classList.toggle("menu-open");
			} else {

				// console.log(event.target)
				// document.addEventListener("mousedown", function () {
				// 	document.querySelector(".header-wrapper").classList.remove("menu-open");
				// })
			}

		})


		// var elements = document.querySelectorAll(".has-droupdown > a");
		// for (var i in elements) {
		// 	if (elements.hasOwnProperty(i)) {
		// 		elements[i].onclick = function () {
		// 			this.parentElement.querySelector(".submenu").classList.toggle("active");
		// 			this.classList.toggle("open");
		// 		};
		// 	}
		// }
		const { logo, color = "default-color" } = this.props;
		let logoUrl = <img src={Eagelpic} alt="Mg Eagle" />;

		// if (logo === "light") {
		// logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Digital Agency" />;
		// } else if (logo === "dark") {
		// 	logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Digital Agency" />;
		// } else if (logo === "symbol-dark") {
		// 	logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Digital Agency" />;
		// } else if (logo === "symbol-light") {
		// 	logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Digital Agency" />;
		// } else {
		// 	logoUrl = <img src="/assets/images/logo/logo.png" alt="Digital Agency" />;
		// }

		return (
			<header className={`header-area header-style-two header--fixed ${color}`}>
				<div className="header-wrapper" >
					<div className="header-left d-flex align-items-center ">
						<div className="logo" style={{ height: "60px", width: "60px" }}>
							<Link to="/">{logoUrl}</Link>
						</div>
						<nav className="mainmenunav d-lg-block ml--50">
							<Scrollspy
								className="mainmenu"
								items={["", "home", "Concept", "gallery", "production", "about", "contact",]}
								currentClassName="is-current"
								offset={-200}
							>
								<hr className="hrt" style={{ backgroundColor: "white", height: "0.1px" }} />
								<li onClick={this.CLoseMenuTrigger}>
									<Link to={`/?redirect=home`} >
										Home
									</Link>
								</li>

								<li onClick={this.CLoseMenuTrigger}>
									<Link to={`/?redirect=Concept`}>
										Concept
									</Link>
								</li>
								<li>
									<a href="https://showcase.battleoftheidols.co/">Gallery</a>
								</li>
								<li onClick={this.CLoseMenuTrigger}>
									<Link to={`/?redirect=production`}>
										Production
									</Link>
								</li>
								<li onClick={this.CLoseMenuTrigger}>
									<Link to={`/?redirect=about`}>
										About
									</Link>
								</li>
								<li onClick={this.CLoseMenuTrigger}>
									<Link to={`/?redirect=contact`}>
										Contact
									</Link>
								</li>

								<li>
									<Link className="buy-ticket rn-btn" to="/register-now"
										style={{
											padding: "0px 26px",
											textAlign: "center",
											fontSize: "20px",
											height: "48px",
											lineHeight: "46px",
											marginTop: "130px"
										}}
									>
										<div> Register </div>
									</Link>
								</li>

								<li>
									<Link className="buy-ticket rn-btn" to="/buy-ticket"
										style={{
											padding: "0px 26px",
											textAlign: "center",
											fontSize: "20px",
											height: "48px",
											lineHeight: "46px",

										}}
									>

										Buy tickets
									</Link>
								</li>

							</Scrollspy>
						</nav>
					</div>
					<div className="header-right">

						{/* <div className="social-share-inner">
							<ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
								{SocialShare.map((val, i) => (
									<li key={i}>
										<a href={`${val.link}`}>{val.Social}</a>
									</li>
								))}
							</ul>
						</div> */}


						<div className="header-btn">

							{/* <a className="rn-btn" href="/buy-ticket">
								<span>Buy tickets</span>
							</a> */}

							<Link className="rn-btn mr-4" to="/register-now" style={{ padding: "0px 27px" }}>
								Register
							</Link>

							<Link className="rn-btn" to="/buy-ticket" style={{ padding: "0px 15px" }}>
								Buy tickets
							</Link>
						</div>

						{/* Start Humberger Menu  */}
						<div className="humberger-menu d-block d-lg-none pl--20">
							<span onClick={this.menuTrigger} className="menutrigger text-white">
								<FiMenu />
							</span>
						</div>
						{/* End Humberger Menu  */}
						<div className="close-menu d-block d-lg-none">

							<span onClick={this.CLoseMenuTrigger} className="closeTrigger">
								<FiX />
							</span>
						</div>
					</div>
				</div>
			</header>
		);
	}
}
export default HeaderThree;
