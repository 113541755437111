
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { components } from "react-select";
// import './CustomOptionsMultiSelect.scss';
import ReactCountryFlag from 'react-country-flag';

const MultiSelectField = ({ value, name, placeholder, className, options, countryData, onChange, isDisabled, props }) => {

  const customStyles = {
    control: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      height: '40px',
      boxShadow: null,
      minHeight: '40px',
      height: '40px',
      boxShadow: '0 !important',
      border: 'solid gray 1px',
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
      '&:hover': {
        border: 'solid gray 1px',
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0',
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      width: '30px',
      display: 'flex',
      justifyContent: 'center'
    }),
    option: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      color: 'black'
    }),
    singleValue: base => ({
      ...base,
      color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333"
      };
    }
  };


  const getCountryData = (value) => {
    let findCountryData = countryData.find((val) => val._id == value);
    return findCountryData
  }

  const customOption = (props) => {

    return (
      <div>
        <components.Option {...props}>
          <div className='d-flex align-items-center'>
            {
              props.value != '+0' ?
                <ReactCountryFlag countryCode={getCountryData(props.value)?.country_code} svg />
                : null
            }
            <span
              className='m-0 py-0 pl-2 text-capitalize'
            >{props.label}</span>
          </div>
        </components.Option>
      </div>
    );
  };


  const ValueContainer = ({ children, ...props }) => (
    <components.ValueContainer {...props}>
      <div className='d-flex align-items-center justify-content-end w-100'>
        {
          props.value != '+0' ?
            <ReactCountryFlag className='mr-1' countryCode={getCountryData(children[0].props.data?.value)?.country_code} svg />
            : null
        }
        {children}
      </div>
    </components.ValueContainer>
  );


  return (
    <Select
      isMulti={false}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      isSearchable={false}
      className={className}
      styles={customStyles}
      isDisabled={isDisabled}
      components={{
        Option: customOption,
        ValueContainer: ValueContainer
      }}
    />
  );
};


const renderMultiSelectField = ({
  input, meta, options, placeholder, onMenuClose, isDisabled, className, countryData, optionBottomText, setActiveTab, style,
}) => (
  <div>
    <MultiSelectField
      {...input}
      countryData={countryData}
      setActiveTab={setActiveTab}
      options={options}
      isDisabled={isDisabled}
      onMenuClose={onMenuClose}
      placeholder={placeholder}
      optionBottomText={optionBottomText}
      className={className}
    />

    {meta.touched && meta.error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{meta.error}</span>}
  </div>
);

export default renderMultiSelectField;
