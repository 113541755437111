import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Container } from "reactstrap";
import src1 from "../../../public/assets/images/modal/1.png";
import src2 from "../../../public/assets/images/modal/2.png";
import src3 from "../../../public/assets/images/modal/3.png";
import src4 from "../../../public/assets/images/modal/4.png";
import src12 from "../../../public/assets/images/modal/cto.png";
import src5 from "../../../public/assets/images/modal/5.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./stype.css";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const PortfolioListContent = [
	{
		image: "image-1",
		category: "CEO",
		title: "Mahmoud Ghorbani",
		src: src4,
		id: "1",
	},
	{
		// image: 'image-5',
		category: "International Marketer",
		title: "Dr. Jason Gahari",
		// src: src5,
		id: "5",
	},
	{
		image: "image-3",
		category: "Director",
		title: "Jim Piccirillo",
		src: src2,
		id: "3",
	},
	{
		image: "image-4",
		category: "Co-executive producer",
		title: "Fred Villari",
		src: src1,
		id: "4",
	},
	{
		image: "image-12",
		category: "CTO",
		title: "DAVID MEYER",
		src: src12,
		id: "2",
	},
];

const PortfolioList = (column, styevariation) => {
	const [modal, setModal] = useState(false);
	const [active, setactive] = useState("");
	const toggle = (value) => {
		if (value.id == 5) {
			console.log("runned");
		} else {
			setactive(value);
			setModal(!modal);
		}
	};

	return (
		<>
			{PortfolioListContent.map((value, index) => (
				<div className="col-lg-2 col-md-6 col-sm-6 col-12" key={index}>
					<div
						className={`portfolio ${column.styevariation}`}
						onClick={(e) => {
							e.preventDefault();
							toggle(value);
						}}
					>
						<div className="thumbnail-inner">
							<div className={`thumbnail ${value.image}`}></div>
							{/* <div className={`bg-blr-image ${value.image}`}></div> */}
						</div>
						<div className="container-fluid text-center p-0 m-0" style={{ zIndex: 9, position: "absolute", top: "16rem" }}>
							<p className="text-uppercase" style={{ marginTop: "0.6rem" }}>
								{value.category}
								<h4 className="">
									<a
										href="/portfolio-details"
										onClick={(e) => {
											e.preventDefault();
										}}
									>
										{value.title}
									</a>
								</h4>
								{value.id == 5 ? null : (
									<div className="portfolio-button">
										<a
											className="rn-btn"
											href="#"
											onClick={(e) => {
												e.preventDefault();
												toggle(value);
											}}
										>
											View Details
										</a>
									</div>
								)}
							</p>
						</div>
					</div>
				</div>
			))}

			{PortfolioListContent.map((value, index) => (
				<Modal isOpen={modal} toggle={toggle} size="md" centered>
					<ModalBody style={{ overflow: "hidden" }}>
						<div style={{ position: "relative" }}>
							<img key={active.id} src={active.src} alt="img"></img>
							<div style={{ position: "absolute", color: "white", right: "1rem", top: "0.5rem" }}>
								<button
									style={{ color: "white", border: "none" }}
									onClick={(e) => {
										e.preventDefault();
										toggle(value);
									}}
								>
									X
								</button>
							</div>
						</div>
					</ModalBody>
				</Modal>
			))}
		</>
	);
};

export default PortfolioList;
