import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Container, Row, Col, Table } from "reactstrap";
import BannerVideo from "../assets/static-images/video/jenifferGif.mp4";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import ipayLogo from "../assets/static-images/ipayLogo.png";
import "./components/RegisterIdols.scss";
import googlePayImage from "../assets/static-images/Asset1.png";
import applePayImage from "../assets/static-images/Asset2.png";
import { Link } from "react-router-dom";
const moment = require("moment-timezone");
let timeZone = moment.tz.guess();



function SubmitSuccessPage(props) {
	let idolsData = props.location.state.idolsData;

	// console.log(idolsData);

	return (
		<div className="active-dark">
			<div className="submitSuccess">
				<video
					autoPlay
					loop
					muted
					style={{
						position: "fixed",
						width: "100vw",
						height: "100vh",
						left: "0",
						top: "0",
						objectFit: "cover",
						zIndex: "0",
					}}
				>
					<source src={BannerVideo} type="video/mp4" />
				</video>
				<Helmet pageTitle="Portfolio Landing" />
				<HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

				<div
					style={{
						width: "100%",
						zIndex: "2",
						paddingTop: "100px",
						position: "relative",
						backgroundColor: "#00000036",
						height: "100vh",
						overflow: "auto",
					}}
				>
					<Container className="pb-5 mt-5">
						<Row>
							<Col md={12} className=" text-center">
								<h3 style={{ color: "#fcb33d", fontSize: "25px" }}> Application Submitted Successfully </h3>
							</Col>

							<Col md={7} className=" text-center mx-auto my-3">
								<div>
									<Table style={{ backgroundColor: "rgb(255 255 255 / 0.80)" }}>
										<tbody>
											<tr style={{ width: "100%" }}>
												<td style={{ border: "solid white 1px", backgroundColor: "rgb(165 169 172 / 54%)", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black" }}>
														{" "}
														Date of Submission{" "}
													</h5>{" "}
												</td>
												<td style={{ border: "solid white 1px", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black", fontWeight: "400" }}>
														{moment.tz(idolsData.registration_date, timeZone).format("DD/MM/yyyy")}
													</h5>{" "}
												</td>
											</tr>

											<tr style={{ width: "100%" }}>
												<td style={{ border: "solid white 1px", backgroundColor: "rgb(165 169 172 / 54%)", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black" }}>
														{" "}
														Application No.{" "}
													</h5>{" "}
												</td>
												<td style={{ border: "solid white 1px", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black", fontWeight: "400" }}>
														{" "}
														BTL-
														{idolsData.idols_unique_id}
														(Note it down please){" "}
													</h5>{" "}
												</td>
											</tr>

											<tr style={{ width: "100%" }}>
												<td style={{ border: "solid white 1px", backgroundColor: "rgb(165 169 172 / 54%)", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black" }}>
														{" "}
														Status{" "}
													</h5>{" "}
												</td>
												<td style={{ border: "solid white 1px", width: "50%" }}>
													{" "}
													<h5 className="text-left m-0" style={{ color: "black", fontWeight: "400" }}>
														{" "}
														Under review{" "}
													</h5>{" "}
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</Col>

							<Col md={12}>
								<div className="text-center">
									<img src={ipayLogo} style={{ height: "80px", width: "80px" }} />
								</div>
							</Col>

							<Col md={12}>
								<div className="text-center">
									<h6 className="my-2" style={{ fontSize: "20px" }}>
										Download our app to track your application.
									</h6>

									<div>
										<a href="https://play.google.com/store/apps/details?id=com.ipay.aussie">
											<img
												className="m-2 rounded overflow-hidden"
												src={googlePayImage}
												style={{ height: "40px", width: "140px", backgroundColor: "rgba(0,0,0,0.7)" }}
												alt="googlePayImage"
											/>
										</a>
										<a href="https://apps.apple.com/us/app/iPayPro-australia/id1515223871?ls=1">
											<img
												className="m-2 rounded overflow-hidden"
												src={applePayImage}
												style={{ height: "40px", width: "140px", backgroundColor: "rgba(0,0,0,0.7)" }}
												alt="applePayImage"
											/>
										</a>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</div>
		</div>
	);
}

export default SubmitSuccessPage;
