import React from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    {Social: <FaFacebookF /> , link: ' https://www.facebook.com/mahmoud.ghorbani'},
    {Social: <FaLinkedinIn /> , link: ' https://www.linkedin.com/in/mahmoud-ghorbani-b566b933/'},
    {Social: <FaInstagram /> , link: 'https://www.instagram.com/mahmoudnghorbani/'},
    {Social: <FaTwitter /> , link: ' https://twitter.com/GhorbaniMg'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
        <div className="wrapper plr--50 plr_sm--20 ">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12 ">
                        <div className="h6 text-center">
                           <br/>
                           <span style={{color:"#fcb33d"}}> ADDRESS</span><br/>
                            MG EAGLE ENT.,<br/>
                            BEVERLY HILLS,<br/>
                            CA 90211
                            
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className=" h6 text-center">
                        <span style={{color:"#fcb33d"}}> PHONE  </span><br/><br className="ex"/>
                    +1 (818) 812 8656 
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className=" text-center ">
                            <div className="h6">
                            <span style={{color:"#fcb33d"}}>  EMAIL </span> <br/><br className="ex"/>
                                info@mgeagleent.com
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wrapper plr--10 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val , i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;