import React, { useState, useEffect, useRef } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
// import Sidevideo from "../assets/static-images/video/side_video.mp4";
// import Bannervideo from "../assets/static-images/video/homepage_banner_video.mp4";
// import mobileBannerVideo from "../assets/static-images/video/Comp 1_1.mp4";
import Vimeo from "@u-wave/react-vimeo";
import Icon from "@mdi/react";
import { mdiMicrophone, mdiMicrophoneOff } from "@mdi/js";
import "./PortfolioPage.scss";
import { useParams, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import ReactPlayer from 'react-player'

const SlideList = [
	{
		textPosition: "text-left",
		category: "Imagine a TRUE Olympic of Music",
		description: "",
		buttonText: "",
		buttonLink: "",
	},
];
const PortfolioLanding = () => {
	// alert(window.innerWidth)
	const [muted, setMuted] = useState(true);
	const [colorr, setColorr] = useState("gray");
	const Concept = useRef(null)
	const production = useRef(null)
	const contact = useRef(null)
	const about = useRef(null)

	let title = "About Us",
		description =
			"Battle of the Idols is a global music event produced by Mahmoud Ghorbani and Sam Riddle. From creator and executive producer of “star search” one of the most successful tv competition series in television history, Sam Riddle has paved the “star search” way discovering young superstars like Selena Gomez, Ariana Grande, Shakira, Beyonce, Taylor Swift, Usher, Britney Spears, Christina Aguilera, and many more";
	const PostList = BlogContent.slice(0, 3);

	let location = useLocation();
			// alert(timeZone)
	useEffect(() => {
		const query = new URLSearchParams(location.search);
		const redirect = query.get("redirect");
		// alert(requested_tab)
		if (redirect == "home") {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: 0 })
			}, 100)
		} else if (redirect == "Concept") {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: Concept.current.offsetTop - 50 })
			}, 100)
		} else if (redirect == "production") {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: production.current.offsetTop - 50 })
			}, 100)
		} else if (redirect == "contact") {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: contact.current.offsetTop - 50 })
			}, 100)
		} else if (redirect == "about") {
			setTimeout(() => {
				window.scrollTo({ behavior: 'smooth', top: about.current.offsetTop - 80 })
			}, 100)
		}

		// Concept.current.scrollIntoView()
		// if (requested_tab == "quick_setup") {
		//   toggle("1")
		// }
	}, [location]);

	return (
		<div className="active-dark portfolioPageScss">
			<Helmet pageTitle="Portfolio Landing" />

			<HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
			{/* Start home Area   */}
			<div id="home" className="fix">
				<div
					className=""
					style={{
						width : window.innerWidth,
						zIndex: "1",
					}}
				>
					{/* <iframe  className="embed-responsive-item" src={Sidevideo}></iframe> */}
					{/* <div style={{
							position: "absolute",
							width: "100%",
							left: "0",
							top: "0",
							height: "100%",
							objectFit: "cover",
							zIndex: "1",
						}}> */}
						{
							window.innerWidth > 500 ?
								<Vimeo video="https://vimeo.com/639400851" autopause={false} width={window.innerWidth} height="auto" background={true} controls={false} autoplay volume={muted?0:1} loop />
								:
								<Vimeo video="https://vimeo.com/639400785" autopause={false} width={window.innerWidth} height="auto" background={true} controls={false} autoplay  volume={muted?0:1} loop />
						}
					{/* </div> */}
						
					<div
						className="muteIconDiv"
						style={{ zIndex: "2", textAlign: "center", cursor: "pointer" }}
						onClick={() => setMuted(!muted)}
						onMouseEnter={() => setColorr("white")}
						onMouseLeave={() => setColorr("gray")}
					>
						{muted ? (
							<Icon path={mdiMicrophoneOff} size="8vh" color={colorr} />
						) : (
							<Icon path={mdiMicrophone} size="8vh" color={colorr} />
						)}
					</div>
				</div>
			</div>
			{/* End home Area   */}
			{/* Start Concept Area */}
			<div id="Concept" ref={Concept} className="fix">
				<div className="rn-blog-area ptb--60 bg_color--5 mb-dec--30">
					<div className="container">
						<div className="row align-items-end">
							<div className="col-lg-12 col-md-12 col-sm-12 col-12">
								<div className="section-title text-center">
									<h2 className="title">The Concept</h2>
									<p>
										Imagine Olympic among the best of the best five rising stars in five different continents, with
										production cities being Los Angeles, Dubai, Mexico City, Tokyo, London, aspiring music contestants
										compete to win the weekly championship in the category so as to return the following week to meet the
										next challenger, the act with the most wins stays in the competition until the final week
									</p>
								</div>
							</div>
						</div>
						<div className="row mt--60 mt_sm--40">
							{PostList.map((value, i) => (
								<div className="col-lg-4 col-md-6 col-12" key={i}>
									<div className="blog blog-style--1">
										<div className="thumbnail">
											<a
												href="/blog-details"
												onClick={(e) => {
													e.preventDefault();
												}}
											>
												<img className="w-100" src={`/assets/images/blog/blog-${value.images}.png`} alt="Blog Images" />
											</a>
										</div>
										<div className="content">
											<p className="blogtype">{value.category}</p>
											<h4 className="title">
												<a
													href="/blog-details"
													onClick={(e) => {
														e.preventDefault();
													}}
												>
													{value.title}
												</a>
											</h4>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
			{/* End Concept Area */}

			{/* Start gallery Area  */}
			{/* <div id="gallery" className="fix border">
					<div className="container">
						<div className="row creative-service justify-content-center">
							<div className="col-lg-12">
								<ServiceList item="3" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
							</div>
						</div>
					</div>
			</div> */}
			{/* End gallery Area  */}

			{/* Start production Area */}
			<div id="production" ref={production} className="fix">
				<div className="portfolio-area ptb--60 bg_color--1">
					<div className="portfolio-sacousel-inner">
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="section-title text-center service-style--3 mb--30 mb_sm--0">
										<h2 className="title">Production Team</h2>

										<p>
											MG EAGLE ENTERTAINMENT© Proudly Presents its 24th year of gives pleasure and delights to its audience
											and viewers, throughout Media, Live Events, Talent Shows, Hospitalities, and Entertainment. MG Eagle
											has contracted some of the most well-known and successful production professionals to lead the way in
											producing these Series and Specials.
										</p>
									</div>
								</div>
							</div>
							<div className="row">
								<PortfolioList styevariation="text-center mt--40" column="col-lg-3 col-md-6 col-sm-6 col-12" item="5" />
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* End production Area */}

			{/* Start About Area */}
			<div id="about" ref={about} className="fix">
				<div className="about-area ptb--10  bg_color--5 ">
					<div className="container">
						<div className="row row--35 align-items-center">
							<div className="col-lg-5">
								<div className="embed-responsive embed-responsive-16by9">
									<Vimeo video="https://vimeo.com/631705864" autoplay muted loop />
								</div>
							</div>
							<div className="col-lg-7">
								<div className="about-inner inner">
									<div className="section-title text-center py-3">
										<h2 className="title abt">{title}</h2>
										<p className="abt">{description}</p>
									</div>
									<div className="row mt--30">{/* <TabTwo tabStyle="tab-style--1" /> */}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* End About Area */}

			{/* Start Contact Area */}
			<div id="contact" ref={contact} className="fix">
				<div className="rn-contact-area ptb--60 bg_color--1">
					<ContactThree contactImages="/assets/images/about/about-9.png" contactTitle="Contact us." />
				</div>
			</div>
			{/* End COntact Area */}

			<FooterTwo />
			{/* Start Back To Top */}
			<div className="backto-top">
				<ScrollToTop showUnder={160}>
					<FiChevronUp />
				</ScrollToTop>
			</div>
			{/* End Back To Top */}
		</div>
	);
};

export default PortfolioLanding;
