import React, { useState, useEffect } from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Input,
	Container,
	Row,
	Col,
	Progress,
	Spinner,
} from "reactstrap";
import Helmet from "../component/common/Helmet";
import HeaderThree from "../component/header/HeaderThree";
import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import "./components/RegisterIdols.scss";
import client from "../utils/HTTPClient";
import apilist from "../utils/apilist";
import BackupIcon from "@material-ui/icons/Backup";
import Icon from "@mdi/react";
import { mdiCloudUpload, mdiClose } from "@mdi/js";
import registerBannerImage1 from "../assets/static-images/jenniferlopez.png";
import ipayLogo from "../assets/static-images/ipayLogo.png";
import BannerVideo from "../assets/static-images/video/jenifferGif.mp4";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import ImageCroper from "./components/ImageCroperModal";
// import ImageCroper from "./ImageCroper";
// import ImageCroper from "./components/ImageCroper";
import DropZone from "react-dropzone";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleAutocomplete from "./components/GoogleAutocomplet";
import moment from "moment";
import Select from "react-select";
import CustomSelect from "./components/CustomSelect";
import CustomOptionsSelect from "./components/CustomOptionsSelect";
import CustomPhoneSelect from "./components/CustomPhoneSelect";
import { Vimeo } from "vimeo";
import * as tus from "tus-js-client";
import axios from "axios";
import Swal from "sweetalert2";
import registerBannerImage from "../assets/static-images/registerBannerImage.png";

let clientVimeo = new Vimeo(
	"6363a4f072fbc42fb5c5c83c7beddb1b9aee901f",
	"Bi+bDOEP4ECS71m0Q8koZgBjzMk7fHg+mmhQDN4UA97ivZwec21PDvYmHengAZ1Nw+vJ4rYVzmYBQ/EcyOhk7IrVE5Fkd5L6XFzPV1DITwjIBB3UnEJuZ+/hjheOuWIS",
	"29c3bc5ef83cfcf0a0be1cc0ec492386"
);

const validate = (values, props) => {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = "Required";
	}
	if (!values.lastName) {
		errors.lastName = "Required";
	}

	if (!values.mobile) {
		errors.mobile = "Required";
	} else if (values.mobile_prefix?.value == "+0") {
		errors.mobile = "Required";
	}

	if (!values.idol_video) {
		errors.idol_video = "Required";
	}

	let checkData = moment(values.dateOfBirth, "DD-MM-YYYY", true);

	if (!values.dateOfBirth) {
		errors.dateOfBirth = "Required";
	} else if (values.dateOfBirth.length < 10) {
		errors.dateOfBirth = "Invalid date!";
	} else if (checkData._d == "Invalid Date") {
		errors.dateOfBirth = "Invalid date!";
	} else if (moment(values.dateOfBirth).isSameOrAfter(moment())) {
		errors.dateOfBirth = "Invalid date!";
	}

	if (!values.email) {
		errors.email = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	if (values.selectCountry?.value == "Select country") {
		errors.selectCountry = "Required";
	}

	if (values.singing_category?.value == "Select Category") {
		errors.singing_category = "Required";
	}

	if (!values.accept_term) {
		errors.accept_term = "Please accept terms and conditions!!";
	}

	if (!values.idolsAddress) {
		errors.idolsAddress = "Required";
	}

	return errors;
};

const renderTermConditionField = ({ input, id, style, placeholder, className, type, meta: { touched, error } }) => (
	<>
		<div className="d-flex align-items-center">
			<input {...input} id={id} style={style} className={className} placeholder={placeholder} type={type} />
			<label htmlFor={id} className="pl-2 m-0">
				{" "}
				I declare that the info I've provided is accurate & complete{" "}
			</label>
		</div>

		{touched && error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>}
	</>
);

const renderField = ({ input, id, style, placeholder, className, type, meta: { touched, error } }) => (
	<>
		<input {...input} id={id} style={style} className={className} placeholder={placeholder} type={type} />

		{touched && error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>}
	</>
);

const renderTextField = ({ input, placeholder, className, name, meta: { touched, error } }) => (
	<>
		<textarea {...input} name={name} placeholder={placeholder} className={className} />

		{touched && error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>}
	</>
);

const renderDatePickerField = ({ input, placeholder, meta: { touched, error } }) => (
	<div>
		<DatePicker
			selected={input.value || null}
			onChange={input.onChange}
			dateFormat="dd-MM-yyyy"
			placeholderText={placeholder}
		/>

		{touched && error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>}
	</div>
);

const renderImageField = (field) => (
	<div>
		<DropZone name={field.name} accept="image/jpeg, image/png, image/gif, image/bmp" onDrop={field.handleOnDrop}>
			{({ getRootProps, getInputProps }) => {
				return (
					<div>
						<label> Profile picture </label>
						<div>
							{field.croppedImgData ? (
								<div
									className="h-100 w-100 d-flex justify-content-between custom-file-upload rounded px-2"
									style={{ cursor: "default" }}
								>
									<div style={{ width: "90%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
										<small className="m-0"> {field.input.value.name} </small>
									</div>

									<div
										style={{ width: "10%", cursor: "pointer" }}
										onClick={() => {
											field.input.onChange(null);
											field.setCroppedImgData(null);
										}}
									>
										<Icon path={mdiClose} size={0.5} color="white" />
									</div>
								</div>
							) : (
								<div className="custom-file-upload rounded" {...getRootProps()}>
									<input {...getInputProps()} />
									<BackupIcon fontSize="small" />
									<span className="ml-2"> Browse </span>
								</div>
							)}
						</div>
					</div>
				);
			}}
		</DropZone>

		{field.imageError && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{field.imageError}</span>}
	</div>
);

const renderVideoField = (field) => (
	<div>
		<DropZone
			name={field.name}
			accept=".mp4, .mkv, .avi"
			onDrop={(filesToUpload) => {
				field.setUploadVideoProgress("0");
				field.input.onChange(filesToUpload[0]);
				if (filesToUpload[0]) {
					field.uploadIdolVideo({ action: "start-uploading", videoData: filesToUpload[0] });
				}
			}}
		>
			{({ getRootProps, getInputProps }) => {
				return (
					<div>
						{field.input.value ? (
							<div
								className="h-100 w-100 d-flex justify-content-between custom-file-upload rounded px-2"
								style={{ cursor: "default" }}
							>
								<div style={{ width: "90%", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
									<small className="m-0"> {field.input.value.name} </small>
								</div>

								{field.input.value.vimeoId ? (
									<div
										style={{ width: "10%", cursor: "pointer" }}
										onClick={() => {
											field.input.onChange(null);
											if (field.input.value.vimeoId) {
												field.stopUploadIdolVideo(field.input.value.vimeoId);
											}
										}}
									>
										<Icon path={mdiClose} size={0.5} color="white" />
									</div>
								) : null}
							</div>
						) : (
							<div>
								<div className="custom-file-upload rounded" {...getRootProps()}>
									<input {...getInputProps()} />
									<BackupIcon fontSize="small" />
									<span className="ml-2"> Browse </span>
								</div>
							</div>
						)}
					</div>
				);
			}}
		</DropZone>

		{field.meta.touched && field.meta.error && (
			<span style={{ padding: "2px 5px", color: "#fcb33d" }}>{field.meta.error}</span>
		)}
	</div>
);

const RegisterIdols = (props) => {
	const { handleSubmit, pristine, submitting, initialize, change } = props;

	const formValues = useSelector((state) => state.form.RegisterIdolsForm.values);

	// console.log(formValues)

	const dispatch = useDispatch();
	const history = useHistory();

	const [allCountry, setAllCountry] = useState([]);
	const [allCountryData, setAllCountryData] = useState([]);
	const [mobilePrefixData, setMobilePrefixData] = useState(null);
	const [dataLoading, setDataLoading] = useState(true);
	const [otpModal, setOtpModal] = useState(false);
	const [otpValue, setOtpValue] = useState("");
	const [otpError, setOtpError] = useState(null);
	const [imageData, setImageData] = useState(null);
	const [croppedImgData, setCroppedImgData] = useState(null);
	const [openModal, setopenModal] = useState(false);
	const [idolsFullAddress, setIdolsFullAddress] = useState(null);
	const [imageError, setImageError] = useState(null);
	const [uploadVideoProgress, setUploadVideoProgress] = useState(null);
	const [carryUploadingData, setCarryUploadingData] = useState(null);
	const [loaderOtp, setLoaderOtp] = useState(false);

	const isValidHttpUrl = (value) => {
		let url;

		try {
			url = new URL(value);

			// if (url.protocol !== "http:" || url.protocol !== "https:") {
			// 	return 'Invalid url!!';
			// }
		} catch (_) {
			return "Invalid url!!";
		}
	};

	const normalizePhone = (value) => {
		if (!value) {
			return value;
		}

		const onlyNums = value.replace(/[^\d]/g, "");
		if (onlyNums.length <= 3) {
			return onlyNums;
		}
		if (onlyNums.length <= 7) {
			return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
		}
		return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
	};

	const normalizeDate = (value) => {
		if (!value) {
			return value;
		}

		const onlyNums = value.replace(/[^\d]/g, "");
		if (onlyNums.length <= 2) {
			return onlyNums;
		}
		if (onlyNums.length <= 4) {
			return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2)}`;
		}
		return `${onlyNums.slice(0, 2)}-${onlyNums.slice(2, 4)}-${onlyNums.slice(4, 8)}`;
	};

	const fetch_country = async () => {
		setDataLoading(true);

		let postdata = {
			show_all: true,
		};

		client.post(apilist.countries_list, postdata, async function (error, response) {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							let gerCountryData = [{ value: "Select country", label: "Select your country" }];
							let gerPrefixData = [{ value: "+0", label: "+0" }];

							response.result.data.map((val) => {
								gerCountryData.push({ value: val._id, label: val.country_name });
								gerPrefixData.push({ value: val._id, label: val.phone_prefix });
							});

							setMobilePrefixData(gerPrefixData);
							setAllCountryData(response.result.data);
							setAllCountry(gerCountryData);
						} else {
							Swal.fire({
								icon: "error",
								title: "Opps...",
								text: response.message,
							});
						}
					} catch (e) { }
				}
			}
			setDataLoading(false);
		});
	};

	const toggleOtpModal = () => setOtpModal(!otpModal);

	const onRegisterSubmit = () => {
		// console.log("runned");
		setDataLoading(true);
		setLoaderOtp(true);

		let getCountryData = allCountryData.find((val) => val._id == formValues.selectCountry.value);

		let imageBaseData = null;
		let imageName = null;

		if (croppedImgData) {
			imageBaseData = croppedImgData.imageData.split(",")[1];
			imageName = `${formValues.mobile.split("-").join("")}.${croppedImgData.imageData.split(";")[0].split("/")[1]}`;
		}

		let postData = {
			first_name: formValues.firstName,
			last_name: formValues.lastName,
			email: formValues.email.toLowerCase(),
			dob: formValues.dateOfBirth,
			mobile: formValues.mobile.split("-").join(""),
			videoId: formValues.idol_video.vimeoId,
			videoLink: formValues.idol_video.vimeoLink,
			videoName: formValues.idol_video.name,
			video_link: formValues.videoUrl,
			video_desc: formValues.description,
			country: getCountryData?.country_code,
			registration_from: 1,
			// verification_code: otp_val,
			mobile_prefix: formValues.mobile_prefix.label,
			singing_category: formValues.singing_category.value,
			imageName: imageName,
			imageData: imageBaseData,
			formatted_address: formValues.idolsAddress,
			lat: idolsFullAddress ? idolsFullAddress.lat : "",
			lng: idolsFullAddress ? idolsFullAddress.lng : "",
			postcode: idolsFullAddress ? idolsFullAddress.postcode : "",
			state: idolsFullAddress ? idolsFullAddress.state : "",
			suburb: idolsFullAddress ? idolsFullAddress.suburb : "",
			street_unit: formValues.street_unit,
		};


		client.put(apilist.register_idols, postData, (error, response) => {
			if (!error) {
				if (typeof response.error !== "undefined") {
					try {
						if (!response.error) {
							setOtpModal(false);
							setLoaderOtp(false);

							history.push({
								pathname: "/submited",
								state: { idolsData: response.result.botiData },
							});
						} else {
							setOtpError(response.message);
							setLoaderOtp(false);

							setTimeout(() => {
								setOtpError(null);
							}, 5000);
						}
					} catch (e) {
						setLoaderOtp(false);
					}
				}
			}
		});

		setDataLoading(false);
	};

	// const onRegisterSubmit = (data) => {
	// 	setDataLoading(true);

	// 	let postData = {
	// 		mobile_prefix: formValues.mobile_prefix.label,
	// 		mobile: formValues?.mobile.split("-").join(""),
	// 	};

	// 	client.put(apilist.send_otp_idols, postData, (error, response) => {
	// 		// console.log(response)
	// 		if (!error) {
	// 			if (typeof response.error !== "undefined") {
	// 				try {
	// 					if (!response.error) {
	// 						setOtpModal(true);
	// 					} else {
	// 						// alert(response.message)
	// 						Swal.fire({
	// 							icon: "error",
	// 							title: "Opps...",
	// 							text: response.message,
	// 						});
	// 					}
	// 				} catch (e) { }
	// 			}
	// 		}
	// 	});
	// 	setDataLoading(false);
	// };

	const uploadIdolVideo = async ({ action, videoData }) => {
		const api = "https://api.vimeo.com/me/videos";

		const body = {
			name: videoData.name,
			upload: {
				approach: "tus",
				size: videoData.size,
			},
		};

		const headers = {
			"Content-Type": "application/json",
			Accept: "application/vnd.vimeo.*+json;version=3.4",
			Authorization: `bearer 29c3bc5ef83cfcf0a0be1cc0ec492386`,
		};

		const response = await axios.post(api, body, { headers });

		let getVideoId = response.data.uri.split("/");
		change(`idol_video`, { vimeoLink: response.data.link, vimeoId: getVideoId[2], name: videoData.name });

		const endpoint = response.data.upload.upload_link;

		let uploadVideo = new tus.Upload(videoData, {
			uploadUrl: endpoint,
			metadata: {
				name: videoData.name,
				description: "Test",
			},
			onSuccess: function (uri) {
				setUploadVideoProgress(null);
			},
			onProgress: function (bytesUploaded, bytesTotal) {
				var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
				setUploadVideoProgress(percentage);
			},

			onError: function (error) {
				console.log("Failed because: " + error);
			},
			onAfterResponse: function (req, res) { },
		});

		setCarryUploadingData(uploadVideo);
		uploadVideo.start();
	};

	const stopUploadIdolVideo = (videoUnqId) => {
		if (carryUploadingData) {
			carryUploadingData.abort();

			clientVimeo.request(
				/*options*/ {
					method: "DELETE",
					path: `/videos/${videoUnqId}`,
				},
				/*callback*/ function (error, body, status_code, headers) {
					if (error) {
						console.log("error");
						console.log(error);
					} else {
						console.log("Uploading cancelled!!");
						console.log(body);
					}
				}
			);

			setUploadVideoProgress(null);
			setCarryUploadingData(null);
		}
	};

	useEffect(() => {
		fetch_country();
	}, []);

	useEffect(() => {
		initialize({
			firstName: "",
			lastName: "",
			email: "",
			dateOfBirth: "",
			mobile: "",
			videoUrl: "",
			idol_video: null,
			description: "",
			selectCountry: { value: "Select country", label: "Select your country" },
			singing_category: { value: "Select Category", label: "Select Category" },
			mobile_prefix: { value: "+0", label: "+0" },
		});
	}, []);

	return (
		<div className="active-dark">
			{window.innerWidth <= 768 ? (
				<div
					style={{
						backgroundImage: `url(${registerBannerImage})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						position: "fixed",
						width: "100vw",
						height: "100vh",
						backgroundPosition: "center top",
					}}
				></div>
			) : (
				<video
					autoPlay
					loop
					muted
					playsInline
					style={{
						position: "fixed",
						width: "100vw",
						height: "100vh",
						left: "0",
						top: "0",
						objectFit: "cover",
						zIndex: "0",
					}}
					src={BannerVideo}
				>
					<source src={BannerVideo} type="video/mp4" />
				</video>
			)}

			<Helmet pageTitle="register-now" />
			<HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />

			<div
				className="registerPage"
				style={{
					width: "100%",
					paddingTop: "80px",
					zIndex: "2",
					position: "relative",
					backgroundColor: "rgba(0, 0, 0, 0.50)",
				}}
			>
				<div className="w-100 overflow-auto">
					<form className="w-100" onSubmit={handleSubmit(onRegisterSubmit)}>
						<Container className="text-white text-center w-100 overflow-auto mx-auto my-0 py-5">
							<Row className="m-0">
								<Col md={6} className="mx-auto p-0 text-left">
									<Row className="m-0">
										<Col className="col-12 text-center">
											<h3 style={{ color: "#FCB33D" }} className="title mb-2">
												AUDITION FORM
											</h3>
										</Col>
									</Row>

									<Row className="m-0">
										<Col md={6} className="py-2 px-3">
											<div>
												<label> First Name* </label>
												<Field type="text" name="firstName" component={renderField} />
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Last Name* </label>
												<Field type="text" name="lastName" component={renderField} />
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Email Address* </label>
												<Field type="email" name="email" component={renderField} />
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Date of Birth* </label>
												<Field
													name="dateOfBirth"
													placeholder="DD-MM-YYYY"
													component={renderField}
													normalize={normalizeDate}
													type="text"
												/>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Select Country* </label>
												<Field
													name="selectCountry"
													component={CustomOptionsSelect}
													countryData={allCountryData}
													options={allCountry}
													onChange={(evnt) => {
														if (evnt.value !== "Select country") {
															let findCountryData = allCountryData.find((val) => val._id == evnt.value);
															change(`mobile_prefix`, { value: findCountryData._id, label: findCountryData.phone_prefix });
														} else {
															change(`mobile_prefix`, { value: "+0", label: "+0" });
														}
													}}
												/>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div className="w-100">
												<label> Mobile no* </label>
												<div className="mobile_style">
													<div className="mobile_prefix">
														<Field
															name="mobile_prefix"
															isDisabled={true}
															component={CustomPhoneSelect}
															countryData={allCountryData}
															options={mobilePrefixData}
														/>
													</div>

													<div className="mobile_num">
														<Field type="tel" name="mobile" component={renderField} normalize={normalizePhone} />
													</div>
												</div>
											</div>
										</Col>

										<Col className="col-12 py-2 px-3">
											<div>
												<label> Send us a video clip of your performance* </label>

												<Row className="m-0">
													<Col md={5} className="order-md-1 order-sm-1 order-1 pl-0 pr-0 pr-md-2 py-2 py-md-0">
														<Field
															name="idol_video"
															type="file"
															uploadIdolVideo={uploadIdolVideo}
															stopUploadIdolVideo={stopUploadIdolVideo}
															uploadVideoProgress={uploadVideoProgress}
															setUploadVideoProgress={setUploadVideoProgress}
															component={renderVideoField}
														/>
													</Col>

													<Col md={7} className="order-md-2 order-sm-3 order-3 pr-0 pl-0 pl-md-2 py-2 py-md-0">
														<Field
															type="url"
															name="videoUrl"
															component={renderField}
															validate={formValues?.videoUrl ? isValidHttpUrl : null}
															placeholder="Paste video url here (optional)"
														/>
													</Col>

													{uploadVideoProgress ? (
														<Col className="order-md-3 order-sm-2 order-2 p-0">
															<Progress
																className="m-0 mt-md-2"
																value={uploadVideoProgress}
																color="primary"
																style={{ height: "7px", fontSize: "7px", borderRadius: "10px" }}
															>
																{" "}
																25%{" "}
															</Progress>
														</Col>
													) : null}
												</Row>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div className="mx-auto">
												<Field
													name="idol_profile"
													type="file"
													imageError={imageError}
													croppedImgData={croppedImgData}
													setCroppedImgData={setCroppedImgData}
													handleOnDrop={(acceptedFiles) => {
														setDataLoading(true);
														var reader = new FileReader();

														reader.readAsDataURL(acceptedFiles[0]);
														reader.onload = () => {
															var img = new Image();

															img.onload = function () {
																if (img.width >= 193 && img.height >= 193) {
																	change(`idol_profile`, acceptedFiles[0]);
																	setImageError(null);
																	setopenModal(true);
																	setImageData({
																		imageName: acceptedFiles[0].name,
																		imageData: reader.result,
																	});
																} else {
																	setImageError("Image dimension should be atleast 193px x 193px");
																	setTimeout(() => {
																		setImageError(null);
																	}, 5000);
																}
															};

															img.src = reader.result;
														};
														setDataLoading(false);
													}}
													component={renderImageField}
												/>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Select Category* </label>

												<Field
													name="singing_category"
													component={CustomSelect}
													options={[
														{ value: "Select Category", label: "Select Category" },
														{ value: "1", label: "Female Vocalist" },
														{ value: "2", label: "Male Vocalist" },
														{ value: "3", label: "Duos/Groups/Bands" },
													]}
												/>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Address* </label>
												<div>
													<Field
														required
														name="idolsAddress"
														onSelectAddress={(data) => {
															try {
																setIdolsFullAddress(data);
															} catch { }
														}}
														countryCode={
															allCountryData.find((val) => val._id == formValues.selectCountry.value)?.country_code
														}
														component={GoogleAutocomplete}
														type="text"
													/>
												</div>
											</div>
										</Col>

										<Col md={6} className="py-2 px-3">
											<div>
												<label> Street name/unit </label>
												<Field type="text" name="street_unit" component={renderField} />
											</div>
										</Col>

										<Col className="col-12 py-2 px-3">
											<div>
												<label> Message </label>

												<Field name="description" className="desc p-3" component={renderTextField} />
											</div>
										</Col>

										<Col className="col-12 py-2 px-3">
											<div className="pl-3">
												<Field
													id="accept_term"
													style={
														window.screen.width < 500 ? { width: "35px", height: "35px" } : { width: "18px", height: "18px" }
													}
													type="checkbox"
													name="accept_term"
													component={renderTermConditionField}
												/>
											</div>
										</Col>

										<Col className="col-12 py-2 px-3">
											<div className="w-100 text-center">
												<button
													className="rn-button-style--2 btn-solid m-0"
													type="submit"
													disabled={uploadVideoProgress || dataLoading || loaderOtp}
												>
													{loaderOtp ? <Spinner size="sm" color="secondary" /> : "Proceed"}
												</button>
											</div>
										</Col>
									</Row>
								</Col>
							</Row>
						</Container>
					</form>

					<div>
						{/* <Modal isOpen={otpModal} centered>
							<ModalBody className="bg-white">
								<div className="text-center d-flex flex-column align-items-center">
									<span className="d-inline-block ml-auto" style={{ cursor: "pointer" }} onClick={toggleOtpModal}>
										<Icon path={mdiClose} size={1} color="black" />
									</span>

									<div className="text-center">
										<h5> Please Enter the OTP to Verify your Account </h5>

										<p style={{ fontSize: "15px", margin: "0" }}>
											{" "}
											OTP(one time password) has been sent to {formValues?.mobile}{" "}
										</p>

										<div className="text-center my-4">
											<div className="d-flex justify-content-center">
												<OtpInput
													inputStyle={{
														height: "3rem",
														width: "3rem",
														margin: "0 0.5rem",
														border: "0.5px solid grey",
														borderRadius: "5px",

														fontSize: "150%",
														textAlign: "center",
														color: "#79797A",
													}}
													numInputs={6}
													errorStyle="error"
													onChange={(val) => {
														setOtpValue(val);
														setOtpError(null);
														if (val.length == 6) {
															processOptCheck(val);
														}
													}}
													isInputNum={true}
													value={otpValue}
												/>
											</div>
											{otpError && <p className={`text-danger text-center`}> {otpError} </p>}
										</div>

										<button
											className="rn-button-style--2 btn-solid mx-auto"
											onClick={() => processOptCheck(otpValue)}
											disabled={loaderOtp}
										>
											{loaderOtp ? <Spinner size="sm" color="secondary" /> : "Validate OTP"}
										</button>

										<p
											style={{ fontSize: "14px", cursor: "pointer" }}
											onClick={() => {
												setOtpError("Otp sent!!");
												onRegisterSubmit();

												setTimeout(() => {
													setOtpError(null);
												}, 5000);
											}}
										>
											{" "}
											Resend OTP{" "}
										</p>
									</div>
								</div>
							</ModalBody>
						</Modal> */}

						{imageData ? (
							<Modal isOpen={openModal} centered>
								<ModalBody className="p-0 m-0">
									<ImageCroper
										src={imageData}
										setCroppedImgData={setCroppedImgData}
										setopenModal={setopenModal}
										setImage={setImageData}
									/>
								</ModalBody>
							</Modal>
						) : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default reduxForm({
	form: "RegisterIdolsForm",
	validate,
	initialized: true,
})(RegisterIdols);
