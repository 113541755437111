const api = {
	validateCutomer: "api/validate_customers",

	// sent_buyTicket_form: "info@battleoftheidols.co",
	// battleoftheidols
	send_otp_idols: "api/idols/send_otp_idols",
	register_idols: "api/idols/register_idols",
	buy_ticket_data: "api/idols/buyTicket_idols_data",
	save_contactus_details: "api/idols/saveContactUsDetails",


	// subscription
	fetch_subscription_invoice: "api/subscription/invoice",
	verify_card_amount: "api/v2/wallet/verify_card_amount",

	fetch_subscription: "api/subscription/fetch",
	subscription_details: "api/subscription/details",
	create_payment_session: "api/subscription/payment_session",
	validate_payment_session: "api/subscription/validate_payment_session",

	// features
	fetchFeatures: "features/fetchFeatures",
	updateFeatures: "features/updateFeatures",
	changeFeatureTableReservation: "features/changeFeatureTableReservation",
	changeFeatureMultiUsers: "features/changeFeatureMultiUsers",

	// sign up user
	save_card: "api/v3/save_card",
	set_default_card: "api/v3/set_default_card",
	create_setupIntent: "api/v3/create_setupIntent",
	merchant_signup_user: "api/v3/merchant_signup_user",
	verify_mobile: "api/v3/verify_mobile",
	request_otp: "api/v3/request_otp",
	user_details: "api/v3/user_details",
	create_business: "api/v3/create_business",
	user_login: "api/v3/merchant_user_login",
	stripe_account_user: "api/v3/stripe_account",

	password_reset_otp: "api/v3/password_reset_otp",
	updateUsername: "api/v3/updateUsername",
	fp_verify_otp: "api/v3/fp_verify_otp",
	check_password_token: "api/v3/check_password_token",
	user_create_password: "api/v3/user_create_password",

	user_change_password: "api/v3/user_change_password",

	updateUserProfile: "api/v3/updateUserProfile",
	deleteUserProfile: "api/v3/deleteUserProfile",

	userDetailsSave: "api/user/userDetailsSave",
	changePin: "api/user/changePin",
	changePassword: "api/user/changePassword",
	validate_pin: "api/user/validate_pin",

	// store/merchant
	update_merchant_details: "api/v4/merchant/update_details",
	fetch_all_stores: "api/v4/merchant/fetch_all",
	create_merchant: "api/v4/merchant/create",
	validate_merchant: "api/v4/merchant/validate",

	savephotoidentityform: "api/v4/merchant/savephotoidentityform",
	fetchdocuments: "api/merchant_api/fetchdocuments",
	merchantuploadDocument: "api/merchant_api/upload_document",
	doc_submit_for_review: "api/merchant_api/doc_submit_for_review",

	remove_document: "api/merchant_api/remove_document",

	sendMobileOtp: "api/v4/merchant/sendMobileOtp",
	storeMobileVerification: "api/v4/merchant/mobileVerification",

	sendEmailOtp: "api/v4/merchant/sendEmailOtp",
	sendEmailOrder: "api/v4/merchant/sendEmailOrder",
	storeEmailVerification: "api/v4/merchant/verifyEmail",
	get_invite_details: "api/v4/merchant/get_invite_details",

	// company
	update_company_details: "api/v4/company/update_details",
	create_company: "api/v4/company/create",
	fetch_company: "api/v4/company/fetch",
	uploadCompayProfile: "api/v4/company/uploadProfile",
	deleteCompayProfile: "api/v4/company/deleteCompayProfile",

	//Authentication
	merchant_register: "api/v3/merchant_signup_app",
	merchant_login: "api/auth/merchant_login",
	merchant_verifyOtp: "api/auth/merchant_mobile_verify",
	// merchant_verifyOtp: "api/auth/merchant_check_otp",
	merchant_newPassword: "api/auth/merchantChangePassword",
	merchant_setIpayPin: "api/auth/MerchantSetIpayPin",
	merchant_resendOtp: "api/auth/merchant_resend_otp",
	merchant_logOut: "api/auth/merchant_logout",
	mesrchant_setBussinessData: "api/v3/set_business_details",

	//Merchant
	merchant_details: "api/merchant_api/getmerchant_details",
	fetch_visitorRegistry: "api/customers/fetchvisitry",
	get_customer_cards: "api/customers/get_customer_cards",
	generate_qr_code: "businessapi/v1/merchant/generate_qr_code",
	updateProfile: "businessapi/v1/merchant/updateProfile",
	updateMercDetails: "api/merchant_api/update_profile",
	deleteStoreProfile: "api/merchant_api/delete_profile",
	updateBusinessInfo: "api/merchant_api/update_business_details",
	generateSelfCheckinPoster: "businessapi/v1/merchant/generateSelfCheckinPoster",
	generatePaymentQrPoster: "businessapi/v1/merchant/generatePaymentQrPoster",
	bank_request_update: "businessapi/v1/merchant/bank_request_update",
	sendOtp: "businessapi/v1/merchant/sendOtp",
	sendDineinUrlRequest: "businessapi/v1/merchant/sendDineinUrlRequest",
	sendDeliveryPartnerEnquiry: "businessapi/v1/merchant/sendDeliveryPartnerEnquiry",
	sendSuspiciousMail: "businessapi/v1/merchant/sendSuspiciousMail",
	sendIncreaseSeatCapRequest: "businessapi/v1/merchant/sendIncreaseSeatCapRequest",
	updateVisitEntry: "businessapi/v1/merchant/updateVisitEntry",
	delete_select_visit: "businessapi/v1/merchant/deleteSelectedVisit",

	createhospsafePdf: "backendapi/pdf/createhospsafePdf",
	downloadHospoPdf: "backendapi/pdf/downloadHospoPdf",
	checkPassword: "businessapi/v1/merchant/checkPassword",

	// POS Category
	getPOSCategory: "api/pos/getcategories",
	addPOSCategory: "api/pos/addcategories",
	editPOSCategory: "api/pos/editcategories?id=",
	deletePOSCategory: "api/pos/deletecategories",

	//POS Sub Categories
	getPosSubCategory: "api/pos/getsubcategories",
	addPosSubCategory: "api/pos/addsubcategories",
	editPosSubCategory: "api/pos/editsubcategories",
	deletePosSubCategory: "api/pos/deletesubcategories",

	//POS Front Side
	posFetchCartList: "api/pos-front/fetch_cart_list",
	posUpdateCartList: "api/pos-front/update_cart_list",
	fetchOrderHistory: "api/pos-front/fetch_order_history",
	addOrderHistory: "api/pos-front/add_order_history",

	assing_register: "api/pos-front/assing_register",
	validate_register: "api/pos-front/validate_register",

	pos_merchant_users: "api/pos-front/store_users",

	//POS Items
	addPOSItems: "api/pos/additem",
	getPOSItems: "api/pos/getitems",
	updatePOSItems: "api/pos/updateitem",
	deletePOSItems: "api/pos/deleteitem",

	addVarients: "api/pos/addvarient",
	getAllVarients: "api/pos/getallvarient",
	updateVarients: "api/pos/updateparentvarient",
	getItemsWithAddOns: "api/pos/get-items-with-addons?id=",
	addOptions: "api/pos/item_options/add_options",
	fetchOptions: "api/pos/item_options/fetch_options",
	editOptions: "api/pos/item_options/edit_options",
	deleteOptions: "api/pos/item_options/delete_options",

	//Transactions
	refund_request: "api/v2/wallet/add_refund_request",
	merchant_transactions: "api/transactions/history_new",
	wallet_details: "api/wallet/wallet_details",
	fetch_filtertranc: "businessapi/v1/transactions/fetch_filtertranc",

	//quick bills
	fetch_bills: "api/v2/quickbills/fetch_bills",
	edit_bills: "api/v2/quickbills/update_bill",
	delete_bill: "api/v2/quickbills/delete_bill",

	//notifications
	fetch_notifications: "businessapi/v1/notifications/allNotifications",

	//contact_requests
	addContactReq: "businessapi/v1/contactrequest/addcontactrequest",

	get_working_time: "businessapi/reservation-settings/get_working_time",
	working_time_Update: "businessapi/reservation-settings/working_time_Update",

	//mail templates
	add_email_templates: "businessapi/emailtemplate/add_email_templates",
	get_email_templates: "businessapi/emailtemplate/get_email_templates",
	update_email_templates: "businessapi/emailtemplate/update_email_templates",
	fetchSingleTemplate: "businessapi/emailtemplate/fetchSingleTemplate",

	//reservation settings
	fetch_tableStructure: "businessapi/reservation-settings/fetch_tableStructure",
	updateRestaurantStructure: "businessapi/reservation-settings/updateRestaurantStructure",
	fetchReservFormSettings: "businessapi/reservation-settings/fetchReservFormSettings",
	updateReservFormSettings: "businessapi/reservation-settings/updateReservFormSettings",

	//reservations
	addbooking: "businessapi/bookings/addbooking",
	fetchbookings: "businessapi/bookings/fetchbookings",
	fetchSinglebooking: "businessapi/bookings/fetchSinglebooking",
	deletebooking: "businessapi/bookings/deletebooking",
	updatebooking: "businessapi/bookings/updatebooking",
	createStripeCheckoutSession: "businessapi/bookings/create_stripe_checkout_session",

	//Users & roles
	getAllRoles: "api/role/getallroles?id=",
	addRoles: "api/role/addrole",
	editRole: "api/role/editroles?id=",
	getAllSubUsers: "api/user/getsubusers?id=",
	AddSubUser: "api/user/adduser",
	editSubUser: "api/user/editsubuser?id=",
	deleteSubUser: "api/user/deletesubuser?id=",

	//Supplier
	addSupplier: "api/supplier/addsupplier",
	getSuppliers: "api/supplier/getsuppliers?id=",
	deleteSuppier: "api/supplier/deletesupplier?id=",
	editSupplier: "api/supplier/editsupplier?id=",

	//Customer
	addCustomer: "api/mcustomer/addcustomer",
	getCustomer: "api/mcustomer/getcustomer?id=",
	deleteCustomer: "api/mcustomer/deletecustomer?id=",
	editCustomer: "api/mcustomer/editcustomer?id=",
	fetch_merchants_offers: "api/v2/offers/fetch_merchants_offers",

	//Taxes
	getTax: "api/tax/gettax",
	addTax: "api/tax/addtax",
	editTax: "api/tax/edittax",
	deleteTax: "api/tax/deletetax",

	//Loyalty Card
	addLoyaltyCard: "api/loyaltycard/addloyaltycard",
	getLoyaltyCard: "api/loyaltycard/getloyaltycard?id=",
	editLoyaltyCard: "api/loyaltycard/editloyaltycard?id=",
	deleteLoyaltyCard: "api/loyaltycard/deleteloyaltycard?id=",

	//Payment Types
	addPaymentTypes: "api/company/paymenttype/addpayment",
	getPaymentType: "api/company/paymenttype/getpayment?id=",
	editPaymentType: "api/company/paymenttype/editpayment?id=",
	deletePaymentType: "api/company/paymenttype/deletepayment?id=",

	//Pos Register
	getPosRegister: "api/pos/getregister?id=",
	addPosRegister: "api/pos/addregister",
	editPosRegister: "api/pos/editregister?id=",
	deletePosRegister: "api/pos/deleteregister?id=",

	//mail
	send_mail: "businessapi/v1/merchant/send_mail",
	sendBusinessTemplateMail: "businessapi/emailtemplate/sendBusinessTemplateMail",

	//delivery partners
	sf_restaurantInfo: "http://dm.speedfood.com.au/hubapi/Restaurant/RestaurantInfo",
	sf_login: "http://dm.speedfood.com.au/hubapi/Authentication/login",

	//reservation
	customer_payment: "ipayapi/v1/customer/direct_payrequest",
	cancel_payment_request: "ipayapi/v1/auth/cancel_payment_request",
	fetchpaymentstatus: "ipayapi/v1/customer/fetchpaymentstatus",

	fetchSinglePaymentgateway: "ipayapi/v1/getSinglePaymentgateway",

	fetchSeatMapFloorsAndrooms: "businessapi/reservation-settings/fetch_seatMap_floorsAndrooms",
	updateSeatMapFloorsAndrooms: "businessapi/reservation-settings/update_seatMap_floorsAndrooms",
	deleteSeatMapFloorsAndrooms: "businessapi/reservation-settings/delete_seatMap_floorsAndrooms",

	fetchSeatMapTableData: "businessapi/reservation-settings/fetch_seatMap_tableData",
	addSeatMapTableData: "businessapi/reservation-settings/add_seatMap_tableData",
	updateSeatMapTableIndexData: "businessapi/reservation-settings/update_seatMap_tableData_index",
	updateSeatMapTableData: "businessapi/reservation-settings/update_seatMap_tableData",
	deleteSeatMapTableData: "businessapi/reservation-settings/delete_seatMap_tableData",

	fetchSeatMapComponentsData: "businessapi/reservation-settings/fetch_seatMap_ComponentsData",
	addSeatMapComponentsData: "businessapi/reservation-settings/add_seatMap_ComponentsData",
	updateSeatMapComponentsData: "businessapi/reservation-settings/update_seatMap_ComponentsData",
	deleteSeatMapComponentsData: "businessapi/reservation-settings/delete_seatMap_ComponentsData",

	fetchSeatMapTextComponents: "businessapi/reservation-settings/fetch_seatMap_TextComponents",
	addSeatMapTextComponents: "businessapi/reservation-settings/add_seatMap_TextComponents",
	updateSeatMapTextComponents: "businessapi/reservation-settings/update_seatMap_TextComponents",
	deleteSeatMapTextComponents: "businessapi/reservation-settings/delete_seatMap_TextComponents",

	// countries
	countries_list: "api/countries/fetch_app",
	countries_list_withflag: "http://api.ipaypro.co/api/countries/fetch_app",

	deletereq: "api/merchant_api/deletereq?id=",
	moneyrequest: "api/merchant_api/moneyrequest",
	moneyrequestDetails: "api/merchant_api/moneyrequestDetails",
	linkrequests: "api/merchant_api/linkrequests",
	payment_status: "api/merchant_api/payment_status",
	payment_status_of_other_payment_mode: "api/merchant_api/payment_status_of_other_payment_mode",
	payment_session: "api/merchant_api/payment_session",
	payment_by_card: "api/merchant_api/payment_by_card",
	card_to_wallet_money_transfer: "api/merchant_api/card_to_wallet_money_transfer",

	customer_send_otp: "api/auth/customer_resend_otp",
	verify_otp: "api/auth/customer_verify_otp",
	login_verification_step: "api/auth/login_verification_step",

	fetchQrFronside: "businessapi/qr-frontside/fetch_qr_fronside",
	updateQrFronside: "businessapi/qr-frontside/update_qr_fronside",

	// stripe connect
	create_connect_account: "api/merchant_api/create_connect_account",
	create_connect_account_user: "api/merchant_api/create_connect_account_user",
	save_connect_account_user: "api/merchant_api/save_connect_account_user",
	update_bank_account: "api/merchant_api/update_bank_account",
	update_stripe_doc: "api/merchant_api/update_stripe_doc",
	stripe_account: "api/merchant_api/stripe_account",
	upload_identity_document: "api/merchant_api/upload_document",
};

export default api;
