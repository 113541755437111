import React, { useState, useEffect } from "react";
import Helmet from "../../component/common/Helmet";
import HeaderThree from "../../component/header/HeaderThree";
import { Field, reduxForm, reset } from "redux-form";
import { useDispatch } from "react-redux";
import {
	Modal,
	ModalBody,
	Container,
	Row,
	Col,
	Card,
	CardText,
	CardBody,
	CardLink,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import "./Buyticket.scss";
import client from "../../utils/HTTPClient";
import apilist from "../../utils/apilist";
import BuyTicketBannerImage2 from "../../assets/static-images/Buy_Ticket-2.jpg";
import { CloseOutline } from "react-ionicons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import losAngelos from "../../assets/static-images/flag_images/los angelos.png";
import maxicoCity from "../../assets/static-images/flag_images/maxico city.png";
import london from "../../assets/static-images/flag_images/london.png";
import dubai from "../../assets/static-images/flag_images/dubai.png";
import tokyo from "../../assets/static-images/flag_images/tokyo.png";
import BuyTicketBannerVideo from "../../assets/static-images/video/Home.mp4";
// import ReactPlayer from 'react-player'
import registerBannerImage from "../../assets/static-images/registerBannerImage.png";

const validate = (values) => {
	// console.log(values, "values")
	const errors = {};

	if (!values.Name) {
		errors.Name = "Required";
	}

	if (!values.email) {
		errors.email = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}
	if (values.selectCountry == "Select country") {
		errors.selectCountry = "Required";
	}

	// console.log(errors, "errrors");
	return errors;
};

const renderField = ({
	input,
	placeholder,
	type,
	className,
	style,
	onkeypress,
	pattern,
	step,
	meta: { touched, error },
}) => (
	<>
		<input
			{...input}
			placeholder={placeholder}
			type={type}
			step={step}
			className={className}
			style={style}
			pattern={pattern}
		/>

		{touched && error && (
			<div className="form__form-group-error d-flex align-items-center" style={{ minWidth: "6rem" }}>
				<span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>
			</div>
		)}
	</>
);

const Buytickets = (props) => {
	const { handleSubmit, pristine, submitting, initialized } = props;

	const dispatch = useDispatch();

	const [modal, setModal] = useState(false);
	const toggle = () => setModal(!modal);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const toggle2 = () => setDropdownOpen((prevState) => !prevState);
	const [countryName, setCountryName] = useState();

	const [showCountryError, setShowCountryError] = useState();
	const [mobileNo, setMobileNo] = useState();
	const [showMobileErroe, setShowMobileErroe] = useState();
	const [messageLoding, setMessageLoding] = useState(true);

	const changeDropOption = (e) => {
		setCountryName(e.currentTarget.textContent);
	};

	const handleChange = (value, data, event, formattedValue) => {
		let dialCode = `(+${data.dialCode}) `;
		setMobileNo(dialCode + value.slice(data.dialCode.length));
	};
	useEffect(() => {
		setMobileNo();
		setCountryName();
	}, []);
	console.log(mobileNo, "mobile");
	const nameInAlpha = (value) => {
		if (value) {
			return value.replace(/[^A-Za-z]/gi, " ");
		}
	};
	const BuyTicketFormSubmit = (data) => {
		setMessageLoding(true);

		let postData2 = { ...data, city_Name: countryName, mobile_No: mobileNo };

		if (!mobileNo) {
			setShowMobileErroe("Required");
		} else if (!countryName) {
			setShowCountryError("Required");
		} else if (countryName && mobileNo) {
			client.put(apilist.buy_ticket_data, postData2, (error, response) => {
				if (!error) {
					if (!response.error) {
						// console.log(response.message, response.result.data);
						// console.log("Added successfully")
						toggle();
						setMessageLoding(false);
						dispatch(reset("buyTicketForm"));
						setMobileNo("(+1)");
						setCountryName("us");
					} else {
						// console.log(response.message);
					}
				} else {
					console.log("Internal error occured.Please contact support.");
				}
			});
		}
	};

	return (
		<div className="active-dark buyticket ">
			{window.innerWidth <= 768 ? (
				<div
					style={{
						backgroundImage: `url(${registerBannerImage})`,
						backgroundSize: "cover",
						backgroundRepeat: "no-repeat",
						position: "fixed",
						width: "100vw",
						height: "100vh",
						backgroundPosition: "center",
					}}
				></div>
			) : (
				<video
					autoPlay
					loop
					muted
					style={{
						position: "fixed",
						width: "100vw",
						height: "100vh",
						left: "0",
						top: "0",
						objectFit: "cover",
						zIndex: "0",
					}}
				>
					<source src={BuyTicketBannerVideo} type="video/mp4" />
				</video>
			)}
			{/* <ReactPlayer  playing={true} muted={true} url={BuyTicketBannerVideo} width={window.innerWidth} height={window.innerHeight} style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    left: "0",
                    top: "0",
                    objectFit: "cover",
                    zIndex: "0",
                }}/> */}

			{/* <video
                autoPlay
                loop
                muted
                style={{
                    position: "fixed",
                    width: "100vw",
                    height: "100vh",
                    left: "0",
                    top: "0",
                    objectFit: "cover",
                    zIndex: "0",
                }}
            >
                <source src={BuyTicketBannerVideo} type="video/mp4" />
            </video> */}
			<Helmet pageTitle="Sign Up and Stay Tuned" />
			<HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
			<div className="">
				<div
					id="BuyTicket"
					className="fix "
					style={{
						width: "100%",
						paddingTop: "80px",
						zIndex: "2",
						position: "relative",
						backgroundColor: "rgba(0, 0, 0, 0.70)",
					}}
				>
					<div className="slider-wrapper ">
						<div
							className="slide  personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center"
							style={{ padding: "25px 0px" }}
						>
							<div className="container  mt-2">
								<div className="row toppc">
									<div className="mx-auto" style={{ textAlign: "center" }}>
										<h3 className="title text-uppercase mx-auto" style={{ color: "#FCB33D" }}>
											Stay tuned for further updates.{" "}
										</h3>
									</div>
									<div className="col-lg-12 Body_style" style={{ minHeight: "100vh", marginLeft: "-10px" }}>
										<div className="col-lg-6" style={{ height: "100%", width: "34%", margin: "auto" }}>
											<Container style={{ color: "white" }}>
												<form onSubmit={handleSubmit(BuyTicketFormSubmit)} autocomplete="off">
													<Row></Row>
													<Row className="singleRow">
														<Col>
															<Row className="roww">
																<label>Name *</label>
															</Row>
															<Row className="roww">
																<Field type="text" normalize={nameInAlpha} name="Name" component={renderField} />
															</Row>
														</Col>
													</Row>
													<Row className="singleRow">
														<Col>
															<Row className="roww">
																<label>Email Address *</label>
															</Row>
															<Row className="roww">
																<Field type="email" name="email" component={renderField} />
															</Row>
														</Col>
													</Row>
													<Row className="singleRow">
														<Col>
															<Row className="roww">
																<label>Mobile no *</label>
															</Row>
															<Row className="roww">
																<PhoneInput
																	country="us"
																	prefix="+"
																	value={mobileNo}
																	onChange={handleChange}
																	name="mobile No"
																	className="form-control"
																	inputProps={{
																		name: "phone",
																		required: true,
																		// autoFocus: true
																	}}
																	inputStyle={{ color: "#ffffff" }}
																/>
																{!mobileNo ? <p style={{ color: "#fcb33d", fontSize: "14px" }}>{showMobileErroe}</p> : null}
															</Row>
														</Col>
													</Row>

													<Row className="singleRow">
														<Col>
															<Row className="roww">
																<label>I would like to get more information on tickets for * </label>
															</Row>
															<Row className="roww">
																<Dropdown isOpen={dropdownOpen} toggle={toggle2}>
																	<DropdownToggle caret className="text-left country_list">
																		{countryName ? ` ${countryName}` : "Select City..."}
																	</DropdownToggle>
																	<DropdownMenu className="text-left country_item">
																		<DropdownItem onClick={changeDropOption}>
																			<img src={losAngelos} className="mr-3" width="20" height="15" /> Los Angelos
																		</DropdownItem>
																		<DropdownItem onClick={changeDropOption}>
																			<img src={maxicoCity} className="mr-3" width="20" height="15" /> Mexico City
																		</DropdownItem>
																		<DropdownItem onClick={changeDropOption}>
																			<img src={london} className="mr-3" width="20" height="15" /> London
																		</DropdownItem>
																		<DropdownItem onClick={changeDropOption}>
																			<img src={dubai} className="mr-3" width="20" height="15" /> Dubai
																		</DropdownItem>
																		<DropdownItem onClick={changeDropOption}>
																			<img src={tokyo} className="mr-3" width="20" height="15" /> Tokyo
																		</DropdownItem>
																	</DropdownMenu>
																</Dropdown>
																{!countryName ? (
																	<p style={{ color: "#fcb33d", fontSize: "14px" }}>{showCountryError}</p>
																) : null}
															</Row>
														</Col>
													</Row>

													<Row className="singleRow" style={{ textAlign: "center" }}>
														<button className="rn-button-style--2 btn-solid mr-auto ml-3" type="submit">
															Submit
														</button>
													</Row>

													<div>
														<Modal
															isOpen={modal}
															toggle={toggle}
															centered
															className="p-0 success_modal"
															style={{ maxWidth: "280px" }}
														>
															<ModalBody className="p-0" style={{ maxWidth: "280px" }}>
																<Card body className="p-0 border-0 ">
																	<CardBody className="p-0 overlay" id="overlay" style={{ maxWidth: "280px" }}>
																		<div className="image_Div">
																			<img
																				src={BuyTicketBannerImage2}
																				alt="Card image cap"
																				className=""
																				style={{ maxWidth: "278px" }}
																			/>
																			<div className="card-img-overlay ">
																				<CloseOutline
																					color={"#fa1418"}
																					height="35px"
																					width="35px"
																					style={{
																						position: "absolute",
																						right: "0px",
																						top: "0px",
																						backgroundColor: "red",
																						color: "white",
																						cursor: "pointer",
																					}}
																					onClick={toggle}
																				/>
																				<div
																					className="text-white d-flex align-items-center justify-content-center flex-column"
																					style={{ marginTop: "53px" }}
																				>
																					<span class="lnr lnr-checkmark-circle"></span>
																					<h3 className="text-white" style={{ fontSize: "25px", marginTop: "10px" }}>
																						Success
																					</h3>
																				</div>
																				<div
																					className="text-center "
																					style={{
																						position: "absolute",
																						bottom: "20px",
																					}}
																				>
																					<CardText
																						className="text-white "
																						style={{ marginRight: "10px", fontSize: "16px", lineHeight: "25px" }}
																					>
																						Thank You! Your enquiry has been sent successfully.
																					</CardText>

																					<CardLink
																						href="#"
																						className=" px-4 py-2  text-white"
																						style={{
																							cursor: "pointer",
																							backgroundColor: "black",
																							border: "1px solid black",
																						}}
																						onClick={toggle}
																					>
																						OKAY
																					</CardLink>
																				</div>
																			</div>
																		</div>
																	</CardBody>

																	<CardBody
																		className="text-center"
																		style={{ maxWidth: "280px", backgroundColor: "#36C285", height: "120px" }}
																	></CardBody>
																</Card>
															</ModalBody>
														</Modal>
													</div>
												</form>
											</Container>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default reduxForm({
	form: "buyTicketForm",
	validate,
	// destroyOnUnmount: false,
	initialize: true,
})(Buytickets);
