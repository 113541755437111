import React, { useState, useRef } from "react";
import { render } from "react-dom";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Button } from 'reactstrap';

const ImageCroperModal = (props) => {

    const [zoomValue, setZoomValue] = useState(0);
    const [cropper, setCropper] = useState();

    const cropperRef = useRef(null);

    // const onCrop = () => {
    //     const imageElement = cropperRef?.current;
    //     const cropper = imageElement?.cropper;
    //     console.log(cropper.getCroppedCanvas().toDataURL());
    // };


    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            props.setImage(null);
            props.setCroppedImgData({
                imageName: props.src.imageName,
                imageData: cropper.getCroppedCanvas().toDataURL(),
            });
            props.setopenModal(false);
        }
    };


    const onChange = (data) => {
        setZoomValue(data / 10)
    }

    return (
        <div className='p-1 bg-white rounded'>
            <div>
                <Cropper
                    src={props.src.imageData}
                    style={{ height: '300px', width: "100%" }}
                    aspectRatio={3 / 3}
                    zoomTo={zoomValue}
                    zoomable={true}
                    zoomOnWheel={false}
                    zoomOnTouch={true}
                    wheelZoomRatio={0.1}
                    guides={false}
                    toggleDragModeOnDblclick= {false}
                    // crop={onCrop}
                    ref={cropperRef}
                    dragMode='move'
                    cropBoxMovable={false}
                    cropBoxResizable={false}
                    // cropend={data => console.log(data)}
                    preview=".img-preview"
                    onInitialized={(instance) => {
                        setCropper(instance);
                    }}
                />
            </div>

            <div className='py-3 px-2 px-sm-4'>
                <Slider
                    min={2}
                    max={20}
                    className='m-0'
                    defaultValue={zoomValue}
                    onChange={onChange}
                    trackStyle={{ backgroundColor: '#24424C' }}
                    railStyle={{ backgroundColor: '#ABE2FB' }}
                />

                <div className='mt-3'>
                    <div className='d-flex align-items-center'>
                        <div
                            className="img-preview border rounded bg-light shadow-sm"
                            style={{ width: "150px", height: "150px", overflow: 'hidden' }}
                        />

                        <div className='d-flex flex-column mx-auto align-items-start'>
                            <h3 className='m-0'> Preview </h3>
                            <Button
                                className='rn-button-style--2 btn-solid my-2'
                                onClick={getCropData}
                            > Crop Image </Button>
                            <Button
                                onClick={() => {
                                    props.setImage(null);
                                    props.setopenModal(false);
                                }}
                            > Cancel </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ImageCroperModal;