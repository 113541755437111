import { combineReducers, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { reducer as reduxFormReducer } from "redux-form";
import rtlReducer from "../redux/reducers/rtlReducer";

import { composeWithDevTools } from "redux-devtools-extension";

const reducer = combineReducers({
	form: reduxFormReducer, // mounted under "form",
	rtl: rtlReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

export default store;
