
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { components } from "react-select";

const MultiSelectField = ({ value, name, placeholder, options, onChange, onMenuClose, optionBottomText, setActiveTab, props }) => {


  const customStyles = {
    control: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      height: '40px',
      width: '100%',
      boxShadow: null,
      minHeight: '40px',
      height: '40px',
      boxShadow: '0 !important',
      border: '1px solid gray',
      borderRadius: '5px',
      '&:hover': {
        border: '1px solid #fcb33d'
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 10px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
    option: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      color: 'black'
    }),
    singleValue: base => ({
      ...base,
      color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333"
      };
    }
  };


  return (
    <Select
      isMulti={false}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      isSearchable={false}
      styles={customStyles}
    />
  );
};


const renderMultiSelectField = ({
  input, meta, options, placeholder, onMenuClose, optionBottomText, setActiveTab, style,
}) => (
  <div>
    <MultiSelectField
      {...input}
      setActiveTab={setActiveTab}
      options={options}
      onMenuClose={onMenuClose}
      placeholder={placeholder}
      optionBottomText={optionBottomText}
    />

    {meta.touched && meta.error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{meta.error}</span>}
  </div>
);

export default renderMultiSelectField;
