
import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { components } from "react-select";
// import './CustomOptionsMultiSelect.scss';
import ReactCountryFlag from 'react-country-flag';

const MultiSelectField = ({ value, name, placeholder, className, options, countryData, onChange, onMenuClose, optionBottomText, setActiveTab, props }) => {

  const customStyles = {
    control: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      height: '40px',
      width: '100%',
      boxShadow: null,
      minHeight: '40px',
      height: '40px',
      boxShadow: '0 !important',
      border: '1px solid gray',
      borderRadius: '5px',
      '&:hover': {
        border: '1px solid #fcb33d'
      }
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 10px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '40px',
    }),
    option: base => ({
      ...base,
      fontFamily: "sans-serif",
      fontSize: '13px',
      color: 'black'
    }),
    singleValue: base => ({
      ...base,
      color: 'white'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333"
      };
    }
  };

  const customOption = (props) => {

    let getCountryData = countryData.find((val) => val._id == props.value);

    return (
      <div>
        <components.Option {...props}>
          <div className='d-flex align-items-center'>
            {
              props.value != 'Select country' ?
                <ReactCountryFlag countryCode={getCountryData?.country_code} svg />
                : null
            }
            <span
              className='m-0 py-0 pl-2 text-capitalize'
            >{props.label}</span>
          </div>
        </components.Option>
      </div>
    );
  };



  return (
    <Select
      isMulti={false}
      name={name}
      value={value}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      classNamePrefix="react-select"
      isSearchable={false}
      className={className}
      styles={customStyles}
      components={{
        Option: customOption,
      }}
    />
  );
};


const renderMultiSelectField = ({
  input, meta, options, placeholder, onMenuClose, className, countryData, optionBottomText, setActiveTab, style,
}) => (
  <div>
    <MultiSelectField
      {...input}
      countryData={countryData}
      setActiveTab={setActiveTab}
      options={options}
      onMenuClose={onMenuClose}
      placeholder={placeholder}
      optionBottomText={optionBottomText}
      className={className}
    />

    {meta.touched && meta.error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{meta.error}</span>}
  </div>
);

export default renderMultiSelectField;
