import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import "./service.scss"
import About from "../../assets/static-images/video/About_us.mp4"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const ServiceList = (props) => {
    return (
        <>       
        <Container>
        <Carousel responsive={responsive}>
      
        {/* <Col >
            <div className="embed-responsive embed-responsive-16by9">
                <iframe title="embedsPage" className="embed-responsive-item" src="https://player.vimeo.com/video/631705864?h=abf56bddeb&color=ffde59&title=0&byline=0&portrait=0"
                    allowfullscreen></iframe>
                </div>
            </Col> */}
            <Col >
            <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
            <iframe src='https://vimeo.com/showcase/8931608/embed' allowfullscreen frameborder='0' 
            style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}>
            </iframe>
            </div>
            </Col>
            {/* <Col >
            <div style={{padding:"56.25% 0 0 0", position:"relative"}}>
                <iframe  src="https://player.vimeo.com/video/631707510?h=9fe7a273c8&color=ffde59&title=0&byline=0&portrait=0"
                    allowfullscreen
                    frameborder='0' style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}}></iframe>
                </div>
            </Col>
            <Col>
            <div className="embed-responsive embed-responsive-16by9">
                <iframe title="embedsPage" className="embed-responsive-item" src="https://player.vimeo.com/video/631705720?h=283e772a21&color=ffde59&title=0&byline=0&portrait=0"
                    allowfullscreen></iframe>
            </div>
            </Col>
            <Col>
            <div className="embed-responsive embed-responsive-16by9">
                <iframe title="embedsPage" className="embed-responsive-item" src="https://player.vimeo.com/video/631707280?h=3cb73e837d&color=ffde59&title=0&byline=0&portrait=0"
                    allowfullscreen></iframe>
            </div>
           </Col>
           <Col>
            <div className="embed-responsive embed-responsive-16by9">
                <iframe title="embedsPage" className="embed-responsive-item" src="https://player.vimeo.com/video/631707205?h=fad0db3e46&color=ffde59&title=0&byline=0&portrait=0"
                    allowfullscreen></iframe>
            </div>
           </Col>
       */}
        </Carousel>
        </Container>
        </>
    )
}

export default ServiceList
