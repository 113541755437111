import React from "react";
import { Field, reduxForm, reset } from "redux-form";
import client from "../../utils/HTTPClient";
import apilist from "../../utils/apilist";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";

const validate = (values, props) => {
	const errors = {};

	if (!values.firstName) {
		errors.firstName = "Required";
	} else if (!/^[a-zA-Z ]*$/.test(values.firstName)) {
		errors.firstName = "Invalid Name";
	}

	if (!values.mobile) {
		errors.mobile = "Required";
	} else if (isNaN(values.mobile)) {
		errors.mobile = "Invalid Number";
	}

	if (!values.message) {
		errors.message = "Required";
	}

	if (!values.email) {
		errors.email = "Required";
	} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = "Invalid email address";
	}

	return errors;
};

const renderField = ({ input, placeholder, type, meta: { touched, error } }) => (
	<>
		<input {...input} placeholder={placeholder} type={type} style={{ color: "white" }} />

		{touched && error && (
			<span style={{ padding: "2px 2px", color: "#fcb33d", position: "relative", top: "-15px" }}>{error}</span>
		)}
	</>
);

const renderTextField = ({ input, placeholder, type, meta: { touched, error } }) => (
	<>
		<textarea {...input} placeholder={placeholder} type={type} style={{ color: "white" }} />

		{touched && error && (
			<span style={{ padding: "2px 5px", color: "#fcb33d", position: "relative", top: "-15px" }}>{error}</span>
		)}
	</>
);

const ContactThree = (props) => {
	const { handleSubmit, pristine, submitting } = props;

	const dispatch = useDispatch();

	const onRegisterSubmit = (values) => {
		console.log(values);
		let postData = { ...values };
		console.log(postData);

		client.put(apilist.save_contactus_details, postData, (error, response) => {
			if (!error) {
				if (!response.error) {
					console.log("Saved Succeffully", response.data.token);
					Swal.fire({
						icon: "success",
						title: "Thanks for reaching out",
						text: `Someone will get back to you soon`,
					});
					dispatch(reset("registerForm"));
				} else {
					console.log(response.message);
					Swal.fire({
						icon: "error",
						title: "Opps...",
						text: `Something went wrong`,
					});
				}
			} else {
				console.log("internal error occured");
			}
		});
	};
	return (
		<div className="contact-form--1">
			<div className="container">
				<div className="row row--35 align-items-start">
					<div className="col-lg-6 order-2 order-lg-1">
						<div className="section-title text-left mb--50">
							<h2 className="title">{props.contactTitle}</h2>
							<p className="description">Please fill out the form below and we will get in touch with you. </p>
						</div>
						<div className="form-wrapper">
							<form onSubmit={handleSubmit(onRegisterSubmit)}>
								<Field type="text" name="firstName" component={renderField} placeholder="Your Name" />
								<Field type="email" name="email" component={renderField} placeholder="Email address" />
								<Field type="text" name="mobile" component={renderField} placeholder="Mobile number" />
								<Field name="message" component={renderTextField} placeholder="Message" />
								<br />
								<button className="rn-button-style--2 btn-solid" type="submit" name="submit" id="mc-embedded-subscribe">
									Submit
								</button>
							</form>
						</div>
					</div>
					<div className="col-lg-6 order-1 order-lg-2">
						<div className="thumbnail mb_md--30 mb_sm--30">
							<img src={`${props.contactImages}`} alt="trydo" />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default reduxForm({
	form: "registerForm",
	validate,
	// destroyOnUnmount: false,
	// initialized: true,
})(ContactThree);
