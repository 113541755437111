import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
// import AlertCircleIcon from "mdi-react/AlertCircleIcon";

let autoComplete;

const loadScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
                script.onreadystatechange = null;
                callback();
            }
        };
    } else {
        script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
};

function handleScriptLoad(updateQuery, autoCompleteRef, country_code) {
    autoComplete = new window.google.maps.places.Autocomplete(
        autoCompleteRef.current,
        { types: ["address"], componentRestrictions: { country: country_code.toLowerCase() != "other" ? country_code : undefined } }
    );
    // autoComplete.setFields(["address_components", "formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () =>
        handlePlaceSelect(updateQuery)
    );
}

async function handlePlaceSelect(updateQuery) {
    const addressObject = autoComplete.getPlace();
    // console.log(addressObject, "addressObject")
    const ac = addressObject.address_components;
    // console.log(ac)
    let suburb = "", state = "", country = "", postcode = "";
    for (let index = 0; index < ac.length; index++) {
        const item = ac[index];

        switch (item["types"][0]) {
            case 'locality':
                suburb = item["long_name"];
                break;
            case 'administrative_area_level_1':
                state = item["long_name"];
                break;
            case 'country':
                country = item["long_name"];
                break;
            case 'postal_code':
                postcode = item["long_name"];
                break;
        }
    }

    const formatted_address = addressObject.formatted_address;
    let lat = addressObject.geometry.location.lat();
    let lng = addressObject.geometry.location.lng();

    updateQuery({ formatted_address, lat, lng, suburb, state, country, postcode });
    // console.log(addressObject);
}


let GoogleAutocomplet = ({
    input, name, meta: { touched, error }, options, placeholder, className, onSelectAddress, style, countryCode, error_div_normal
}) => {

    const autoCompleteRef = useRef(null);

    const setSelectedAddress = (data) => {
        // props.OnAddressSelect(data);
        input.onChange(data.formatted_address)
        try {
            onSelectAddress(data)
        } catch { }
    }

    useEffect(() => {
        let country_code = countryCode ? countryCode.toLowerCase() : "au";
        handleScriptLoad(setSelectedAddress, autoCompleteRef, country_code.toLowerCase())
        // loadScript(
        //     `https://maps.googleapis.com/maps/api/js?key=${"AIzaSyBHd20Bwp4sAXXS0l1BzJQDd-xvjOhtBOY"}&libraries=places`,
        //     () => handleScriptLoad(setSelectedAddress, autoCompleteRef, country_code.toLowerCase())
        // );
    }, [countryCode]);

    return (
        <div className={`form__form-group-input-wrap ${className}`}>
            <input
                {...input}
                ref={autoCompleteRef}
                name={name}
                // defaultValue={props.defaultValue}
                options={options}
                style={style}
                placeholder={placeholder}
                autoComplete='false'
            />
            {touched && error && <span style={{ padding: "2px 5px", color: "#fcb33d" }}>{error}</span>}

            {/* {touched && error && (
                (error_div_normal ?
                    <span className="form__form-group-error">
                        {error}
                    </span>
                    :
                    <div className="form__form-group-error d-flex align-items-center">
                        <span style={{ padding: "2px 5px", backgroundColor: "#ffdce0" }} className="rounded mb-3">
                            <AlertCircleIcon className="mr-1" size={14} />
                            {error}
                        </span>
                    </div>
                )
            )} */}
        </div>
    )
}


GoogleAutocomplet.propTypes = {
    input: PropTypes.shape({
        onChange: PropTypes.func,
        name: PropTypes.string,
    }).isRequired,
    meta: PropTypes.shape({
        touched: PropTypes.bool,
        error: PropTypes.string,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })),
    placeholder: PropTypes.string,
    className: PropTypes.string,
};

GoogleAutocomplet.defaultProps = {
    meta: null,
    options: [],
    placeholder: '',
    className: '',
};


export default GoogleAutocomplet;